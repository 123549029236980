import React, { useContext, useEffect, useState } from "react";
import { Light } from "../themes/Light";
import { Context } from "../contexts.js/Context";
import { TERipple } from "tw-elements-react";
import LoadingModal from "./LoadingModal";
import { createCheckoutSession } from "../services/createCheckoutSession";
import { useAuth } from "../hooks/useAuth";
import { showError } from "../utils/showError";
import { getOrderStatus } from "../services/getOrderStatus";
import { addToCart } from "../services/addToCart";

export default function CheckoutModal(props) {
  const colors = Light;
  const context = useContext(Context);
  const auth = useAuth();

  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [showAddingToCartModal, setShowAddingToCartModal] = useState(false);

  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        console.log(orderId);
        if (orderId) {
          const response = await getOrderStatus(auth.authed.access_token, {
            id: orderId,
          });

          if (response.error) {
          } else {
            if (response.order.status === "completed") {
              props.reset();
              setOrderId(null);
            }
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [orderId]);

  const onClickPlaceOrder = async (e) => {
    e.stopPropagation();
    setShowModal(true);
    setIsPlacingOrder(true);

    const response = await createCheckoutSession(
      {
        products: props.products.map((product) => {
          return {
            ...product,
            stripeAccountId: context.getCurrentSellerStripeAccount(),
            sendTo: context.sendTo,
          };
        }),
        commission: context.getCommission(),
        shipping: context.getShipping(),
        stripeAccountId: context.getCurrentSellerStripeAccount(),
        sendTo: context.sendTo,
      },
      auth.authed.access_token
    );

    if (response.error) {
      setShowModal(false);
      showError(response.error);
    } else {
      setIsPlacingOrder(false);
      console.log(response.orderId);
      setOrderId(response.orderId);
      setTimeout(() => {
        setShowModal(false);
        window.open(response.url, "_blank", "noreferrer");
      }, 3000);
    }
  };

  const onClickAddToCart = async (e) => {
    e.stopPropagation();
    setShowAddingToCartModal(true);
    setIsAddingToCart(true);

    const response = await addToCart(
      {
        products: props.products.map((product) => {
          return {
            ...product,
            stripeAccountId: context.getCurrentSellerStripeAccount(),
            sendTo: context.sendTo,
          };
        }),
        commission: context.getCommission(),
        shipping: context.getShipping(),
        stripeAccountId: context.getCurrentSellerStripeAccount(),
        sendTo: context.sendTo,
      },
      auth.authed.access_token
    );

    if (response.error) {
      setShowAddingToCartModal(false);
      showError(response.error);
    } else {
      setIsAddingToCart(false);
      setTimeout(() => {
        context.fetchInCart();
        if (response.success) {
          props.setShowModal(false);
          setShowAddingToCartModal(false);
        }
      }, 3000);
    }
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
            onClick={(e) => {
              e.stopPropagation();
              props.setShowModal && props.setShowModal(false);
            }}
          >
            <div className="relative my-6 mx-auto max-w-3xl w-full">
              {/*content*/}
              <div className="justify-center items-center border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div
                  className="flex flex-row justify-center p-5 rounded-t-lg w-full"
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.primaryText,
                  }}
                >
                  <h3 className="text-2xl font-semibold">Checkout</h3>
                </div>
                {/*body*/}
                <div className="relative flex flex-col items-center p-6 w-full gap-6">
                  {props.products.map((product, index) => (
                    <div key={index} className="w-full">
                      <div className="flex flex-row w-full justify-between font-bold">
                        <h1>{product.name}</h1>
                        <h1>
                          US$
                          {(product.unitAmount * product.quantity).toFixed(2)}
                        </h1>
                      </div>
                      <div className="flex flex-row w-full justify-between opacity-60 text-xs">
                        <h1>QTY {product.quantity}</h1>
                        <h1>US${product.unitAmount} each</h1>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`flex flex-col gap-1 p-3 rounded-b-lg w-full`}
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.primaryText,
                  }}
                >
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-sm">Total:</p>
                    <p className="font-bold text-sm">
                      ${context.getTotal().toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-sm">Commission:</p>
                    <p className="font-bold text-sm">
                      ${context.getCommission().toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-sm">Fulfillment:</p>
                    <p className="font-bold text-sm">
                      ${context.getShipping().toFixed(2)}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between">
                    <p className="font-bold text-lg">Grand Total:</p>
                    <p className="font-bold text-lg">
                      ${context.getGrandTotal().toFixed(2)}
                    </p>
                  </div>
                  <TERipple
                    rippleColor="light"
                    className="w-full flex flex-row justify-end"
                  >
                    <button
                      className="w-full inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                      type="button"
                      style={{
                        backgroundColor: colors.secondary,
                        color: colors.secondaryText,
                      }}
                      onClick={(e) => onClickAddToCart(e)}
                    >
                      Add To Cart
                    </button>
                  </TERipple>
                  <TERipple
                    rippleColor="light"
                    className="w-full flex flex-row justify-end"
                  >
                    <button
                      className="w-full inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                      type="button"
                      style={{
                        backgroundColor: colors.secondary,
                        color: colors.secondaryText,
                      }}
                      onClick={(e) => onClickPlaceOrder(e)}
                    >
                      Place Order
                    </button>
                  </TERipple>
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <LoadingModal
              showModal={showModal}
              isLoading={isPlacingOrder}
              loadingTitle={"Generating Payment Link"}
              doneTitle={"You've Been bazarred !"}
              showTick={true}
            />
          )}
          {showAddingToCartModal && (
            <LoadingModal
              showModal={showAddingToCartModal}
              isLoading={isAddingToCart}
              loadingTitle={"Adding To Cart"}
              doneTitle={"Added To Cart"}
              showTick={true}
            />
          )}
        </>
      ) : null}
    </>
  );
}
