import { BERoutes } from "../routes/BERoutes";

export const getOrderStatus = async (bearer, data) => {
  try {
    const endpoint = `${process.env.REACT_APP_API_URL}${BERoutes.orderStatus}`;

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${bearer}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // Convert JavaScript object to JSON string
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { error: errorData.detail };
    }

    const successData = await response.json(); // Parse the JSON response
    // Handle the data received from the server
    return successData;
  } catch (error) {
    // Handle errors
    console.error("Error (getOrderStatus)", error);
    return { error: "Error (getOrderStatus)" };
  }
};
