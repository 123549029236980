import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./views/auth/SignIn";
import SignUp from "./views/auth/SignUp";
import { FERoutes } from "./routes/FERoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Context } from "./contexts.js/Context";
import { useEffect, useState } from "react";
import NavigationBar from "./views/navigationBar/NavigationBar";
import Home from "./views/home/Home";
import Profile from "./views/profile/Profile";
import Settings from "./views/settings/Settings";
import { getCurrentUser } from "./services/getCurrentUser";
import SideBar from "./views/navigationBar/SideBar";
import Dashboard from "./views/admin/dashboard/Dashboard";
import Reseller from "./views/admin/reseller/Reseller";
import Catalog from "./views/admin/Catalog/Catalog";
import Payment from "./views/payment/Payment";
import { Country } from "country-state-city";
import ForgotPassword from "./views/auth/ForgotPassword";
import ResetPassword from "./views/auth/ResetPassword";
import Cart from "./components/Cart";
import { inCart } from "./services/inCart";
import { isEmpty } from "./utils/isEmpty";
import { useAuth } from "./hooks/useAuth";

export default function App() {
  const [recommendations, setRecommendations] = useState([]);
  const [user, setUser] = useState({});

  const [countries, setCountries] = useState({});

  const [sendTo, setSendTo] = useState(null);

  const [showCart, setShowCart] = useState(false);
  const [inCartOrder, setInCartOrder] = useState(null);

  const auth = useAuth();

  const calculateTotalCostOfProduct = () => {
    setRecommendations((prevRecommendations) => {
      return prevRecommendations.map((recommendation) => {
        return {
          ...recommendation,
          "Total cost":
            recommendation["Price Per Unit"] *
            recommendation["Purchase quantity"],
        };
      });
    });
  };

  const getTotal = () => {
    return recommendations.reduce((accumulator, recommendation) => {
      return accumulator + recommendation["Total cost"];
    }, 0);
  };

  const getCommission = () => {
    return getTotal() * 0.1;
  };

  const getTotalQuantity = () => {
    return recommendations.reduce((accumulator, recommendation) => {
      return accumulator + recommendation["Purchase quantity"];
    }, 0);
  };

  const getShipping = () => {
    return getTotalQuantity() * (sendTo === "FBA" ? 0.5 : 1);
  };

  const getGrandTotal = () => {
    return getTotal() + getCommission() + getShipping();
  };

  const getCurrentSeller = () => {
    const sellers = recommendations.filter(
      (recommendation) => recommendation["Purchase quantity"] > 0
    );

    return sellers.length !== 0 ? sellers[0]["Sellers_ID"] : null;
  };

  const getCurrentSellerStripeAccount = () => {
    const sellers = recommendations.filter(
      (recommendation) => recommendation["Purchase quantity"] > 0
    );

    return sellers.length !== 0 ? sellers[0]["STRIPE ACCOUNT ID"] : null;
  };

  const fetchCurrentUser = async (access_token) => {
    // if (isEmpty(user)) {
    const response = await getCurrentUser(access_token);
    if (response.error) {
    } else {
      if (response) setUser(response);
    }
    // }
  };

  const resetContext = async () => {
    setRecommendations([]);
    setUser({});
  };

  const initializeCountries = () => {
    const tempCountries = {};
    Country.getAllCountries().forEach(
      (country) => (tempCountries[country.name] = country)
    );
    setCountries(tempCountries);
  };

  const onClickCartIcon = () => {
    inCartOrder && setShowCart(true);
  };

  const fetchInCart = async () => {
    const response = await inCart(auth.authed.access_token);
    if (!response.error && response.order) {
      setInCartOrder({
        ...response.order,
        details: JSON.parse(response.order.details),
      });
    }
  };

  useEffect(() => {
    initializeCountries();
    fetchInCart();
  }, []);

  return (
    <Context.Provider
      value={{
        recommendations,
        setRecommendations,
        calculateTotalCostOfProduct,
        getTotal,
        getCommission,
        getTotalQuantity,
        getShipping,
        getGrandTotal,
        user,
        setUser,
        fetchCurrentUser,
        resetContext,
        countries,
        setCountries,
        getCurrentSeller,
        getCurrentSellerStripeAccount,
        sendTo,
        setSendTo,
        onClickCartIcon,
        inCartOrder,
        fetchInCart,
      }}
    >
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<SignIn />} />

          <Route path={FERoutes.SignIn} element={<SignIn />} />
          <Route path={FERoutes.SignUp} element={<SignUp />} />
          <Route path={FERoutes.ForgotPassword} element={<ForgotPassword />} />
          <Route path={FERoutes.ResetPassword} element={<ResetPassword />} />
          <Route
            path={FERoutes.NavigationBar}
            element={
              <ProtectedRoute>
                <NavigationBar />
              </ProtectedRoute>
            }
          >
            <Route path={FERoutes.Home.substring(1)} element={<Home />} />
            <Route path={FERoutes.Profile.substring(1)} element={<Profile />} />
            <Route
              path={FERoutes.Settings.substring(1)}
              element={<Settings />}
            />
            <Route path={FERoutes.Payment.substring(1)} element={<Payment />} />
          </Route>
          <Route
            path={FERoutes.SideBar}
            element={
              <ProtectedRoute>
                <SideBar />
              </ProtectedRoute>
            }
          >
            <Route
              path={FERoutes.Dashboard.substring(1)}
              element={<Dashboard />}
            />
            <Route
              path={FERoutes.Reseller.substring(1)}
              element={<Reseller />}
            />
            <Route path={FERoutes.Catalog.substring(1)} element={<Catalog />} />
          </Route>
        </Routes>
      </BrowserRouter>
      {showCart && (
        <Cart
          showModal={showCart}
          setShowModal={setShowCart}
          products={inCartOrder.details}
          // reset={reset}
        />
      )}
    </Context.Provider>
  );
}
