import React, { useState } from "react";
import { Tick } from "react-crude-animated-tick";
import { TERipple } from "tw-elements-react";
import { Light } from "../themes/Light";
import OrderModal from "./OrderModal";

export default function ResellerModal(props) {
  const colors = Light;
  const headerKeys = [
    "Order Id",
    "Total Quantity",
    "Send To",
    "Total Amount",
    "Status",
  ];
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const onClickRow = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setShowModal(true);
  };
  return (
    <>
      {props.showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
            onClick={() => props.setShowModal && props.setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="justify-center items-center border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-row justify-center p-5 rounded-t">
                  <h3 className="text-2xl font-semibold">{props.email}</h3>
                </div>
                {/*body*/}
                <div className="relative flex flex-col items-center p-6">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                    <thead
                      className="uppercase"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.primaryText,
                      }}
                    >
                      <tr>
                        {headerKeys.map((key, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="px-3 py-3 text-left"
                          >
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {props.orders.map((order, index) => (
                        <tr
                          key={index}
                          className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 border-b cursor-pointer"
                          onClick={(e) => onClickRow(e, index)}
                        >
                          {headerKeys.map((key, index) => (
                            <td key={index} className="px-3 py-3 text-left">
                              {order[key]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <OrderModal
              showModal={showModal}
              setShowModal={setShowModal}
              orderId={
                selectedIndex !== -1 ? props.orders[selectedIndex].id : ""
              }
              details={
                selectedIndex !== -1
                  ? JSON.parse(props.orders[selectedIndex].details)
                  : []
              }
            />
          </div>
        </>
      ) : null}
    </>
  );
}
