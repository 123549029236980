export const FERoutes = {
  SignIn: "/SignIn",
  SignUp: "/SignUp",
  NavigationBar: "/NavigationBar",
  Home: "/Home",
  Profile: "/Profile",
  Settings: "/Settings",

  SideBar: "/SideBar",
  Dashboard: "/Dashboard",
  Reseller: "/Reseller",
  Catalog: "/Catalog",

  Payment: "/Payment",

  ForgotPassword: "/ForgotPassword",
  ResetPassword: "/ResetPassword",

  //old
};
