import React from "react";
import { Light } from "../themes/Light";
import { RiDeleteBin5Line } from "react-icons/ri";

export const Counter = (props) => {
  const colors = Light;

  const onChangeInput = (value) => {
    let inputValue = parseInt(value);
    if (inputValue < props.MOQ) {
      inputValue = props.MOQ;
    }
    props.onChangeInput && props.onChangeInput(inputValue);
  };

  return (
    <div
      className="flex flex-row rounded-md overflow-hidden w-30 justify-between"
      style={{ backgroundColor: colors.primary, color: colors.primaryText }}
    >
      <div
        className={`flex flex-row justify-center items-center w-10 ${
          props.value === 0 ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        style={{
          backgroundColor: colors.secondary,
          color: colors.secondaryText,
        }}
        onClick={props.onDec}
      >
        {props.value === props.MOQ ? (
          <RiDeleteBin5Line />
        ) : (
          <p className={`mt-[-2px] font-black text-center `}>-</p>
        )}
      </div>
      {/* <p className={`grow text-center`}>{props.value}</p> */}
      <input
        className={`w-16 text-center`}
        style={{ backgroundColor: colors.primary, color: colors.primaryText }}
        value={props.value}
        type="number"
        onChange={(e) => onChangeInput(e.target.value)}
      />
      <div
        className={`cursor-pointer w-10`}
        onClick={props.onInc}
        style={{
          backgroundColor: colors.secondary,
          color: colors.secondaryText,
        }}
      >
        <p className={`mt-[-2px] font-black text-center`}>+</p>
      </div>
    </div>
  );
};
