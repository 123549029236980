import { toast } from "react-toastify";

const errorToast = (message) => {
  toast.error(message);
};

const successToast = (message) => {
  toast.success(message);
};

export { errorToast, successToast };
