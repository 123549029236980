import React, { useContext, useEffect, useState } from "react";
import { Light } from "../../../themes/Light";
import { Context } from "../../../contexts.js/Context";
import { getAnalytics } from "../../../services/getAnalytics";
import { useAuth } from "../../../hooks/useAuth";

export default function Dashboard() {
  // State to manage the navbar's visibility
  const colors = Light;
  const auth = useAuth();

  const [analytics, setAnalytics] = useState([]);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    const response = await getAnalytics(auth.authed.access_token);
    if (response.error) {
    } else {
      if (response) setAnalytics(response);
    }
  };

  return (
    <div className="pt-10">
      <h1 className="text-center text-3xl font-bold uppercase">Dashboard</h1>
      <div class="flex flex-wrap justify-center mt-10">
        {analytics.map((analytic) => (
          <div class="p-4 max-w-sm w-96">
            <div
              class="flex rounded-lg h-full py-4 flex-col"
              style={{
                backgroundColor: colors.primary,
                color: colors.primaryText,
              }}
            >
              <div class="flex flex-col items-center mb-3 gap-4">
                <p
                  className="text-4xl font-bold"
                  style={{
                    color: colors.secondary,
                  }}
                >
                  {analytic.value}
                </p>
                <h2 class="text-lg">{analytic.title}</h2>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
