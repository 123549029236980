import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Light } from "../../themes/Light";

import logoPNG from "../../assets/images/logo.png";
import { Outlet, useNavigate } from "react-router-dom";
import { FERoutes } from "../../routes/FERoutes";
import { Context } from "../../contexts.js/Context";
import { saveVisits } from "../../services/saveVisits";
import { useAuth } from "../../hooks/useAuth";
import { MdSupportAgent } from "react-icons/md";

export default function NavigationBar() {
  // State to manage the navbar's visibility
  const colors = Light;
  const navigate = useNavigate();
  const context = useContext(Context);
  const auth = useAuth();
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    saveVisits({ page: "NavigationBar", email: auth.authed.user.email });
  }, []);

  // Array containing navigation items
  const navItems = [
    { id: 1, text: "Home", route: `${FERoutes.NavigationBar}${FERoutes.Home}` },
    {
      id: 2,
      text: "Profile",
      route: `${FERoutes.NavigationBar}${FERoutes.Profile}`,
    },
    {
      id: 3,
      text: "Settings",
      route: `${FERoutes.NavigationBar}${FERoutes.Settings}`,
    },
    {
      id: 4,
      text: "Logout",
      handler: () => {
        auth.logout();
        context.resetContext();
        navigate(FERoutes.SignIn);
      },
    },
  ];
  return (
    <div>
      <div
        className="flex justify-between items-center h-16 w-full mx-auto px-4"
        style={{ backgroundColor: colors.primary, color: colors.primaryText }}
      >
        {/* Logo */}
        {/* <h1 className="w-full text-3xl font-bold text-[#00df9a]">REACT.</h1> */}

        <img className="h-4/6" src={logoPNG} />

        {/* Desktop Navigation */}
        <ul className="hidden md:flex">
          {navItems.map((item) => (
            <li
              key={item.id}
              className={`p-4 hover:bg-[${colors.secondary}] rounded-xl m-2 cursor-pointer duration-300 hover:text-[${colors.secondaryText}]`}
              onClick={() =>
                item.route ? navigate(item.route) : item.handler()
              }
            >
              {item.text}
            </li>
          ))}
        </ul>

        {/* <div className="hidden md:flex">
          <MdSupportAgent size={20} />
        </div> */}

        <div className="flex flex-row gap-2">
          <div
            className="hidden md:flex text-center text-xs cursor-pointer relative"
            onClick={context.onClickCartIcon}
            style={{ cursor: context.inCartOrder ? "pointer" : "not-allowed" }}
          >
            {context.inCartOrder && (
              <div className="rounded-full h-2 w-2 bg-red-500 absolute right-0"></div>
            )}
            <AiOutlineShoppingCart size={20} />
          </div>
          <a
            className="hidden md:flex text-center text-xs cursor-pointer"
            href="mailto:hello@bazarr.ai"
            title="For support, send an email to hello@bazarr.ai."
          >
            <MdSupportAgent size={20} />
          </a>
        </div>

        {/* Mobile Navigation Icon */}
        <div onClick={handleNav} className="fixed md:hidden right-5">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        {/* Mobile Navigation Menu */}
        <ul
          className={
            nav
              ? `fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 ease-in-out duration-500 z-50`
              : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%] z-50"
          }
          style={{ backgroundColor: colors.primary }}
        >
          {/* Mobile Logo */}
          <img className="h-10 ml-4 mt-3" src={logoPNG} />

          {/* Mobile Navigation Items */}
          {navItems.map((item) => (
            <li
              key={item.id}
              className="p-4 border-b rounded-xl hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer border-gray-600"
              onClick={() => {
                handleNav();
                item.route ? navigate(item.route) : item.handler();
              }}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
      <Outlet />
    </div>
  );
}
