import React, { useContext, useEffect, useState } from "react";
import { Light } from "../../../themes/Light";
import { Context } from "../../../contexts.js/Context";
import { getResellers } from "../../../services/getResellers";
import { useAuth } from "../../../hooks/useAuth";
import ResellerModal from "../../../components/ResellerModal";

export default function Reseller() {
  // State to manage the navbar's visibility
  const colors = Light;
  const auth = useAuth();
  const headerKeys = ["Email", "Historical Purchases", "Visits"];
  const [_, setResellers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchResellers();
  }, []);

  const fetchResellers = async () => {
    const response = await getResellers(auth.authed.access_token);
    if (response.error) {
    } else {
      if (response) {
        setResellers(response);
        setTableData(
          // ["Order Id", "Total Quantity", "Total Amount", "Status"]
          response.map((row) => {
            return {
              ...row,
              Email: row.email,
              "Historical Purchases": row.orders.length,
              Visits: row.visits,
              orders: row.orders.map((order) => {
                return {
                  ...order,
                  "Order Id": order.id,
                  "Total Quantity": order.totalQuantity,
                  "Send To": order.sendTo,
                  "Total Amount": order.totalAmount,
                  Status: order.status,
                };
              }),
            };
          })
        );
      }
    }
  };

  const onClickRow = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
  };

  return (
    <div className="pt-10 px-10">
      <h1 className="text-center text-3xl font-bold uppercase">Reseller</h1>
      <div className="w-full overflow-auto mb-6 mt-10 rounded-t-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead
            className="uppercase"
            style={{
              backgroundColor: colors.primary,
              color: colors.primaryText,
            }}
          >
            <tr>
              {headerKeys.map((key, index) => (
                <th key={index} scope="col" className="px-3 py-3 text-left">
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr
                key={index}
                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 border-b cursor-pointer"
                onClick={() => onClickRow(index)}
              >
                {headerKeys.map((key, index) => (
                  <td key={index} className="px-3 py-3 text-left">
                    {row[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ResellerModal
        showModal={showModal}
        setShowModal={setShowModal}
        email={selectedIndex !== -1 ? tableData[selectedIndex].email : ""}
        orders={selectedIndex !== -1 ? tableData[selectedIndex].orders : []}
      />
    </div>
  );
}
