import React, { useContext, useEffect, useState } from "react";
import { Light } from "../../themes/Light";

import { TERipple } from "tw-elements-react";
import { Context } from "../../contexts.js/Context";
import { Counter } from "../../components/Counter";
import { createCheckoutSession } from "../../services/createCheckoutSession";
import { showError } from "../../utils/showError";
import { useAuth } from "../../hooks/useAuth";
import { getRecommendations } from "../../services/getRecommendations";
import { getGenerationsToday } from "../../services/getGenerationsToday";
import LoadingModal from "../../components/LoadingModal";
import { getOrderStatus } from "../../services/getOrderStatus";
import CheckoutModal from "../../components/CheckoutModal";
import { saveVisits } from "../../services/saveVisits";
import AlertModal from "../../components/AlertModal";

export default function Home() {
  // State to manage the navbar's visibility
  const colors = Light;
  const context = useContext(Context);
  const auth = useAuth();
  const [fields, setFields] = useState({
    // budget: null,
    sellerId: null,
  });
  const [isBazarrMeUPEnabled, setIsBazarrMeUPEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOrderSuccessModal, setShowOrderSuccessModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const [selectedRecommendationIndex, setSelectedRecommendationIndex] =
    useState(-1);
  const [selectedProducts, setSelectProducts] = useState([]);

  const [generations, setGenerations] = useState({});

  const headerKeys = [
    "ASIN",
    "Price Per Unit",
    "Your Estimated Monthly Sales",
    "Recommended Sell Price Per Unit",
    "Gross Profit Per Unit",
    "ROI",
    // "Sellers_ID",
    "MOQ",
    // "Recommended Purchase Quantity",
    "Purchase quantity",
    "Add To Cart",
  ];

  const [headerArrow, setHeaderArrow] = useState({
    ASIN: { show: false, state: "down" },
    "Price Per Unit": { show: true, state: "down" },
    "Your Estimated Monthly Sales": { show: false, state: "down" },
    "Recommended Sell Price Per Unit": { show: true, state: "down" },
    "Gross Profit Per Unit": { show: true, state: "down" },
    ROI: { show: true, state: "down" },
    MOQ: { show: true, state: "down" },
    "Purchase quantity": { show: false, state: "down" },
    "Add To Cart": { show: false, state: "down" },
  });

  const unitsRight = {
    ASIN: "",
    "Price Per Unit": "",
    "Your Estimated Monthly Sales": "",
    "Recommended Sell Price Per Unit": "",
    "Gross Profit Per Unit": "",
    ROI: "%",
    MOQ: "",
    "Recommended Purchase Quantity": "",
    Sellers_ID: "",
    "Units Prediction": "",
    Supplier: "",
  };
  const unitsLeft = {
    ASIN: "",
    "Price Per Unit": "$",
    "Your Estimated Monthly Sales": "",
    "Recommended Sell Price Per Unit": "$",
    "Gross Profit Per Unit": "$",
    ROI: "",
    MOQ: "",
    "Recommended Purchase Quantity": "",
    Sellers_ID: "",
    "Units Prediction": "",
    Supplier: "",
  };

  useEffect(() => {
    fetchGenerationsToday();
  }, [context.recommendations]);

  useEffect(() => {
    setIsBazarrMeUPEnabled(generations.nGenerations === generations.total);
    setFields((prevField) => {
      return { ...prevField, sellerId: generations.sellerId };
    });
  }, [generations]);

  const renderRow = (key, row, recommendationsIndex) => {
    switch (key) {
      case "Purchase quantity":
        return (
          <Counter
            value={row[key]}
            MOQ={row["MOQ"]}
            onDec={() => {
              context.setRecommendations((prevRecommendations) => {
                return [
                  ...prevRecommendations.slice(0, recommendationsIndex),
                  {
                    ...prevRecommendations[recommendationsIndex],
                    "Purchase quantity":
                      prevRecommendations[recommendationsIndex][
                        "Purchase quantity"
                      ] === prevRecommendations[recommendationsIndex]["MOQ"]
                        ? 0
                        : prevRecommendations[recommendationsIndex][
                            "Purchase quantity"
                          ] === 0
                        ? 0
                        : prevRecommendations[recommendationsIndex][
                            "Purchase quantity"
                          ] - 1,
                  },
                  ...prevRecommendations.slice(recommendationsIndex + 1),
                ];
              });
              context.calculateTotalCostOfProduct();
            }}
            onInc={() => {
              const currentSeller = context.getCurrentSeller();
              if (
                !currentSeller ||
                currentSeller ===
                  context.recommendations[recommendationsIndex]["Sellers_ID"]
              ) {
                context.setRecommendations((prevRecommendations) => {
                  return [
                    ...prevRecommendations.slice(0, recommendationsIndex),
                    {
                      ...prevRecommendations[recommendationsIndex],
                      "Purchase quantity":
                        prevRecommendations[recommendationsIndex][
                          "Purchase quantity"
                        ] === 0
                          ? prevRecommendations[recommendationsIndex]["MOQ"]
                          : prevRecommendations[recommendationsIndex][
                              "Purchase quantity"
                            ] + 1,
                    },
                    ...prevRecommendations.slice(recommendationsIndex + 1),
                  ];
                });
                context.calculateTotalCostOfProduct();
              } else {
                setShowAlertModal(true);
                setSelectedRecommendationIndex(recommendationsIndex);
              }
            }}
            onChangeInput={(value) => {
              context.setRecommendations((prevRecommendations) => {
                return [
                  ...prevRecommendations.slice(0, recommendationsIndex),
                  {
                    ...prevRecommendations[recommendationsIndex],
                    "Purchase quantity": value,
                  },
                  ...prevRecommendations.slice(recommendationsIndex + 1),
                ];
              });
              context.calculateTotalCostOfProduct();
            }}
          />
        );
      case "Add To Cart":
        return (
          <input
            type="checkbox"
            className="text-blue-500 border-2 border-blue-500 focus:ring-2 focus:ring-blue-200"
            checked={row["Purchase quantity"] > 0 ? true : false}
            onChange={() => {
              context.setRecommendations((prevRecommendations) => {
                return [
                  ...prevRecommendations.slice(0, recommendationsIndex),
                  {
                    ...prevRecommendations[recommendationsIndex],
                    "Purchase quantity":
                      row["Purchase quantity"] > 0 ? 0 : row["MOQ"],
                  },
                  ...prevRecommendations.slice(recommendationsIndex + 1),
                ];
              });
              context.calculateTotalCostOfProduct();
              saveVisits({
                page: "addToCart",
                email: auth.authed.user.email,
              });
            }}
          />
        );
      case "Gross Profit Per Unit":
        return row[key].toFixed(2);
      case "ASIN":
        return (
          <a
            className={"underline hover:text-[#3e62ff]"}
            href={row["AMAZON LINK"]}
            target="_blank"
          >
            {row[key]}
          </a>
        );
      default:
        return row[key];
    }
  };

  const onClickContinue = () => {
    context.setRecommendations((prevRecommendations) => {
      const tempPrevRecommendations = prevRecommendations.map(
        (recommendation) => {
          return { ...recommendation, "Purchase quantity": 0 };
        }
      );
      tempPrevRecommendations[selectedRecommendationIndex][
        "Purchase quantity"
      ] = tempPrevRecommendations[selectedRecommendationIndex]["MOQ"];
      return tempPrevRecommendations;
    });
    setShowAlertModal(false);
  };

  const reset = () => {
    // setFields({ budget: null, sellerId: null });
    setFields({ sellerId: null });
    context.setRecommendations([]);
    setShowModal(false);
    setShowOrderSuccessModal(true);
    setTimeout(() => {
      setShowOrderSuccessModal(false);
    }, 3000);
  };

  const onClickCheckOut = async () => {
    setShowModal(true);
    const products = context.recommendations
      .filter((recommendation) => recommendation["Purchase quantity"] > 0)
      .map((recommendation) => {
        return {
          name: String(recommendation.ASIN),
          unitAmount: recommendation["Price Per Unit"],
          quantity: parseInt(recommendation["Purchase quantity"]),
        };
      });
    setSelectProducts(products);
  };

  const fetchRecommendations = async () => {
    setIsLoading(true);
    const response = await getRecommendations(auth.authed.access_token, fields);
    if (response.error) {
      showError(response.error);
      setIsLoading(false);
    } else {
      context.setRecommendations(
        response.recommendations.map((row) => {
          return {
            ...row,
            MOQ: parseFloat(row["MOQ"]),
            "Gross Profit Per Unit": (row["ROI"] * row["Price Per Unit"]) / 100,
            "Purchase quantity": 0,
            "Total cost": 0,
          };
        })
      );
      setIsLoading(false);
    }
  };

  const onChangeField = (key, value) => {
    setFields((prevFields) => {
      const tempFields = { ...prevFields };
      tempFields[key] = value;
      return tempFields;
    });
  };

  const fetchGenerationsToday = async () => {
    const response = await getGenerationsToday(auth.authed.access_token);
    if (response.error) {
      showError(response.error);
    } else {
      setGenerations(response);
    }
  };

  const onClickArrow = async (key) => {
    context.setRecommendations((prevRecommendations) => {
      const sortedRecommendations = [...prevRecommendations];
      headerArrow[key].state === "up"
        ? sortedRecommendations.sort((a, b) => a[key] - b[key])
        : sortedRecommendations.sort((a, b) => b[key] - a[key]);
      return sortedRecommendations;
    });

    setHeaderArrow((prevHeaderArrow) => {
      return {
        ...prevHeaderArrow,
        [key]: {
          ...prevHeaderArrow[key],
          state: prevHeaderArrow[key].state === "up" ? "down" : "up",
        },
      };
    });
  };

  return (
    <div className={`flex flex-col p-10 gap-10`}>
      <div className={`flex flex-row justify-around gap-10 `}>
        {/* <div className={`flex flex-col w-2/4`}>
          <label
            htmlFor="helper-text"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Budget (USD)
          </label>
          <input
            id="helper-text"
            aria-describedby="helper-text-explanation"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Budget"
            value={fields.budget}
            onChange={(e) => onChangeField("budget", e.target.value)}
          />
          <p
            id="helper-text-explanation"
            className="mt-2 text-sm text-gray-500 dark:text-gray-400"
          >
            Please enter the budget you would like to spend on purchasing
            products.
          </p>
        </div> */}
        <div className={`flex flex-col w-2/4`}>
          <label
            htmlFor="helper-text"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Amazon Seller ID/Merchant Token
          </label>
          <input
            id="helper-text"
            aria-describedby="helper-text-explanation"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Amazon Seller ID/Merchant Token"
            value={fields.sellerId}
            onChange={(e) => onChangeField("sellerId", e.target.value)}
          />
          <p
            id="helper-text-explanation"
            className="mt-2 text-sm text-gray-500 dark:text-gray-400"
          >
            Please enter your Amazon Seller ID/Merchant Token
          </p>
        </div>
        <div className={`flex flex-col grow`}>
          <label
            htmlFor="helper-text"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Ship to
          </label>
          <select
            id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => context.setSendTo(e.target.value)}
            value={context.sendTo}
          >
            <option selected disabled>
              {"Please select"}
            </option>
            <option>FBA</option>
            <option>3PL</option>
          </select>
          <p
            id="helper-text-explanation"
            className="mt-2 text-sm text-gray-500 dark:text-gray-400"
          >
            Please enter the destination where you want to ship it
          </p>
        </div>
        {/* <div className={`flex flex-col grow`}>
          <label
            htmlFor="helper-text"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Months
          </label>
          <select
            id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => onChangeField("months", e.target.value)}
            value={fields.months}
          >
            <option selected disabled>
              {"Please select month"}
            </option>
            {[...Array(6).keys()]
              .map((i) => i + 1)
              .map((option, index) => (
                <option key={index}>{option}</option>
              ))}
          </select>
          <p
            id="helper-text-explanation"
            className="mt-2 text-sm text-gray-500 dark:text-gray-400"
          >
            Please enter in how months you would like to sell those products.
          </p>
        </div> */}
      </div>
      <TERipple
        rippleColor="light"
        className="w-full flex flex-row justify-center"
      >
        <button
          className="mb-1 inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
          type="button"
          style={{
            backgroundColor: colors.secondary,
            color: colors.secondaryText,
            cursor: isBazarrMeUPEnabled ? "not-allowed" : "pointer",
          }}
          onClick={fetchRecommendations}
          disabled={isBazarrMeUPEnabled}
        >
          Bazarr me up (
          {`${generations.nGenerations ? generations.nGenerations : 0}/${
            generations.total ? generations.total : 0
          }`}
          )
        </button>
      </TERipple>
      <div className="flex flex-col gap-2">
        <p className="text-left font-bold">
          Chosen Marketplace : Amazon USA{" "}
          <span className="text-left font-bold text-xs">
            (Lead Time - Up To 21 Business Days)
          </span>
        </p>
        <div className="flex flex-col gap-2">
          <p>Hey Bazarrians!</p>
          <p>
            When you click &quot;Bazarr me up&quot; you'll receive a curated
            list of products. To complete a purchase, ensure your cart meets
            each product's MOQ (Minimum Order Quantity) and an overall MOQ of
            $1,000 from this list. Then, you should check out before clicking
            &quot;Bazarr me up&quot; again.
          </p>
          <p>
            Please note: Once you've clicked &quot;Bazarr me up&quot; you won't
            be able to return to this specific list to add more products.
          </p>
          <p>
            Once you complete the purchase, we will contact you by email
            regarding fulfillment details.
          </p>
        </div>
      </div>
      {isBazarrMeUPEnabled && (
        <p className="text-center text-red-500">
          * Recommendations can be generated only 3 times a day.
        </p>
      )}
      {context.getCurrentSeller() && (
        <p className="text-left font-bold">
          Supplier: {context.getCurrentSeller()}
        </p>
      )}
      <div className="w-full overflow-auto rounded-t-lg flex flex-row justify-center items-end">
        {isLoading && (
          <div className="absolute" role="status">
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: colors.secondary }}
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        )}
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead
            className="uppercase"
            style={{
              backgroundColor: colors.primary,
              color: colors.primaryText,
            }}
          >
            <tr>
              {headerKeys.map((key, index) => (
                <th key={index} scope="col" className="px-3 py-3 text-center">
                  <div className="flex flex-row gap-1">
                    <p>{key}</p>
                    {headerArrow[key].show &&
                      (headerArrow[key].state === "up" ? (
                        <p
                          className="cursor-pointer"
                          onClick={() => onClickArrow(key)}
                        >
                          &uarr;
                        </p>
                      ) : (
                        <p
                          className="cursor-pointer"
                          onClick={() => onClickArrow(key)}
                        >
                          &darr;
                        </p>
                      ))}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr key={0} className="h-12"></tr>
            ) : (
              context.recommendations.map((row, recommendationsIndex) => (
                <tr
                  key={recommendationsIndex}
                  className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                >
                  {headerKeys.map((key, index) => (
                    <td key={index} className="px-3 py-3 text-center">
                      {unitsLeft[key]}
                      {renderRow(key, row, recommendationsIndex)}
                      {unitsRight[key]}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div
        className={`flex flex-col gap-1 p-3 rounded-b-lg`}
        style={{
          backgroundColor: colors.primary,
          color: colors.primaryText,
        }}
      >
        <div className="flex flex-row justify-between">
          <p className="font-bold text-sm">Total:</p>
          <p className="font-bold text-sm">${context.getTotal().toFixed(2)}</p>
        </div>
        <TERipple
          rippleColor="light"
          className="w-full flex flex-row justify-center mt-4 mb-1"
        >
          <button
            className="w-1/2 inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
            type="button"
            style={{
              backgroundColor: colors.secondary,
              color: colors.secondaryText,
              cursor:
                context.getTotalQuantity() > 1 &&
                context.getGrandTotal() >= 1000 &&
                context.sendTo !== null
                  ? "pointer"
                  : "not-allowed",
            }}
            disabled={
              !(
                context.getTotalQuantity() > 1 &&
                context.getGrandTotal() >= 1000 &&
                context.sendTo !== null
              )
            }
            onClick={onClickCheckOut}
          >
            Checkout ({context.getTotalQuantity()})
          </button>
        </TERipple>
        <a
          className="text-center text-xs cursor-pointer"
          href="mailto:hello@bazarr.ai"
        >
          For support, send an email to hello@bazarr.ai.
        </a>
      </div>
      * Shipping and fulfillment fees are not included in the table above
      {showModal && (
        <CheckoutModal
          showModal={showModal}
          setShowModal={setShowModal}
          products={selectedProducts}
          reset={reset}
        />
      )}
      {showOrderSuccessModal && (
        <LoadingModal
          showModal={showOrderSuccessModal}
          isLoading={false}
          loadingTitle={""}
          doneTitle={
            "Thank you for your purchase. Your order has been successfully processed"
          }
          showTick={true}
        />
      )}
      {showAlertModal && (
        <AlertModal
          showModal={showAlertModal}
          setShowModal={setShowAlertModal}
          onClickContinue={onClickContinue}
          heading={"Are you sure?"}
          desc="You have selected a product from a different seller. Continuing will remove previous products from the cart. Are you sure you want to continue?"
        />
      )}
    </div>
  );
}
