import React, { useContext, useEffect, useState } from "react";
import { Light } from "../../themes/Light";
import { Context } from "../../contexts.js/Context";
import { getOrder } from "../../services/getOrder";
import { useAuth } from "../../hooks/useAuth";
import OrderModal from "../../components/OrderModal";

export default function Profile() {
  // State to manage the navbar's visibility
  const colors = Light;
  const context = useContext(Context);
  const auth = useAuth();

  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const headerKeys = ["Order Id", "Total Quantity", "Total Amount", "Status"];

  useEffect(() => {
    context.fetchCurrentUser(auth.authed.access_token);
    fetchOrder();
  }, []);

  const onClickRow = (e, index) => {
    e.stopPropagation();
    setSelectedIndex(index);
    setShowModal(true);
  };

  const fetchOrder = async () => {
    const response = await getOrder(auth.authed.access_token);
    if (response.error) {
    } else {
      if (response) {
        setOrders(
          response.map((order) => {
            return {
              ...order,
              "Order Id": order.id,
              "Total Quantity": order.totalQuantity,
              "Total Amount": order.totalAmount,
              Status: order.status,
            };
          })
        );
      }
    }
  };

  return (
    <section className="pt-10 bg-blueGray-50">
      <div className="w-full lg:w-4/12 px-4 mx-auto">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-10">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div
                className="flex flex-row justify-center items-center relative w-36 h-36 rounded-full text-5xl"
                style={{
                  backgroundColor: colors.primary,
                  color: colors.primaryText,
                }}
              >
                <p className="uppercase">
                  {context.user.name ? context.user.name.substr(0, 1) : ""}
                </p>
              </div>
            </div>
            <div className="w-full px-4 text-center mt-10">
              <div className="flex justify-center py-4 lg:pt-4 pt-8">
                <div className="p-3 text-center w-52">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    {context.user.nGenerations}
                  </span>
                  <span className="text-sm text-blueGray-400">
                    Recommendations
                  </span>
                </div>
                <div className="p-3 text-center w-52">
                  <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-600">
                    {context.user.nOrders}
                  </span>
                  <span className="text-sm text-blueGray-400">Purchases</span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center my-12">
            <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 uppercase">
              {context.user.name}
            </h3>
            <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
              {context.user.email}
            </h3>
          </div>
        </div>
        <h1 className="text-4xl text-center uppercase mb-4">
          Transaction History
        </h1>
        <div className="w-full overflow-auto mb-10 rounded-t-lg ">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead
              className="uppercase"
              style={{
                backgroundColor: colors.primary,
                color: colors.primaryText,
              }}
            >
              <tr>
                {headerKeys.map((key, index) => (
                  <th key={index} scope="col" className="px-3 py-3 text-center">
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orders.map((row, index) => (
                <tr
                  key={index}
                  className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer"
                  onClick={(e) => onClickRow(e, index)}
                >
                  {headerKeys.map((key, index) => (
                    <td key={index} className="px-3 py-3 text-center">
                      {row[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedIndex !== -1 && (
        <OrderModal
          showModal={showModal}
          setShowModal={setShowModal}
          orderId={orders[selectedIndex].id}
          details={JSON.parse(orders[selectedIndex].details)}
        />
      )}
    </section>
  );
}
