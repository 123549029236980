import React, { useContext, useEffect, useRef, useState } from "react";
import { Light } from "../../../themes/Light";
import { Context } from "../../../contexts.js/Context";
import { useAuth } from "../../../hooks/useAuth";
import { TERipple } from "tw-elements-react";
import ProductModal from "../../../components/ProductModal";
import { getProducts } from "../../../services/getProducts";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { deleteProduct } from "../../../services/deleteProduct";
import { showError } from "../../../utils/showError";
import Papa from "papaparse";
import { bulkProduct } from "../../../services/bulkProduct";
import { successToast } from "../../../libs/toast";
import LoadingModal from "../../../components/LoadingModal";

export default function Catalog() {
  // State to manage the navbar's visibility
  const colors = Light;
  const context = useContext(Context);
  const auth = useAuth();
  const headerKeys = [
    "",
    "ASIN",
    "Price Per Unit",
    "Your Estimated Monthly Sales",
    "Recommended Sell Price Per Unit",
    "Gross Profit Per Unit",
    "ROI",
    "MOQ",
    "Recommended Purchase Quantity",
    "Sellers_ID",
    "Units Prediction",
    "Supplier",
    "STRIPE ACCOUNT ID",
    "AMAZON LINK",
  ];
  const headerKeysFields = {
    ASIN: "ASIN",
    "Price Per Unit": "pricePerUnit",
    "Your Estimated Monthly Sales": "yourEstimatedMonthlySales",
    "Recommended Sell Price Per Unit": "recommendedSellPricePerUnit",
    "Gross Profit Per Unit": "grossProfitPerUnit",
    ROI: "ROI",
    MOQ: "MOQ",
    "Recommended Purchase Quantity": "recommendedPurchaseQuantity",
    Sellers_ID: "sellerId",
    "Units Prediction": "unitsPrediction",
    Supplier: "supplier",
    "STRIPE ACCOUNT ID": "stripeAccountId",
    "AMAZON LINK": "amazonLink",
  };
  const unitsRight = {
    ASIN: "",
    "Price Per Unit": "",
    "Your Estimated Monthly Sales": "",
    "Recommended Sell Price Per Unit": "",
    "Gross Profit Per Unit": "",
    ROI: "%",
    MOQ: "",
    "Recommended Purchase Quantity": "",
    Sellers_ID: "",
    "Units Prediction": "",
    Supplier: "",
    "STRIPE ACCOUNT ID": "",
    "AMAZON LINK": "",
  };
  const unitsLeft = {
    ASIN: "",
    "Price Per Unit": "$",
    "Your Estimated Monthly Sales": "",
    "Recommended Sell Price Per Unit": "$",
    "Gross Profit Per Unit": "",
    ROI: "",
    MOQ: "",
    "Recommended Purchase Quantity": "",
    Sellers_ID: "",
    "Units Prediction": "",
    Supplier: "",
    "STRIPE ACCOUNT ID": "",
    "AMAZON LINK": "",
  };
  const [products, setProducts] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-2);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showUploadingCSVModal, setShowUploadingCSVModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    selectedIndex !== -2 ? setShowModal(true) : setShowModal(false);
  }, [selectedIndex]);

  const fetchProducts = async () => {
    const response = await getProducts(auth.authed.access_token);
    if (response.error) {
    } else {
      if (response) {
        setProducts(response);
      }
    }
  };

  const onClickRow = (index) => {
    setSelectedIndex(index);
  };

  const onClickDelete = async (e, index) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setIsDeleting(true);
    const response = await deleteProduct(
      auth.authed.access_token,
      products[index].ASIN
    );
    if (response.error) {
      showError(response.error);
      setShowDeleteModal(false);
    } else {
      if (response) fetchProducts();
      setIsDeleting(false);
      setTimeout(async () => {
        setShowDeleteModal(false);
      }, 3000);
    }
  };

  function openFileExplorer() {
    inputRef.current.value = "";
    inputRef.current.click();
  }

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setShowUploadingCSVModal(true);
      setIsUploading(true);
      const fileReader = new FileReader();

      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(e.target.files[0]);
    }
  };

  const csvFileToArray = async (string) => {
    const parsedData = Papa.parse(string, {
      header: true, // Treats the first row as header
      skipEmptyLines: true, // Skip empty lines
    });

    const response = await bulkProduct(auth.authed.access_token, {
      products: parsedData.data
        .filter((row) => {
          for (const key in row) {
            if (row[key] && row[key] !== "\r") return true;
          }
          return false;
        })
        .map((row) => {
          const obj = {};
          Object.entries(headerKeysFields).forEach(([key, field]) => {
            obj[field] = row[key];
          });
          return obj;
        }),
    });
    if (response.error) {
      showError(response.error);
      setShowUploadingCSVModal(false);
    } else {
      if (response) {
        fetchProducts();
        setIsUploading(false);
        setTimeout(async () => {
          setShowUploadingCSVModal(false);
        }, 3000);
      }
    }
  };

  return (
    <div className="pt-10 px-10">
      <h1 className="text-center text-3xl font-bold uppercase">Catalog</h1>
      <div className="flex flex-row my-10 gap-10">
        <input
          placeholder="fileInput"
          className="hidden"
          ref={inputRef}
          type="file"
          multiple={true}
          onChange={handleChange}
          accept={".csv"}
        />
        <TERipple
          rippleColor="light"
          className="w-full flex flex-row justify-center mt-2"
        >
          <button
            className="w-full inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
            type="button"
            style={{
              backgroundColor: colors.secondary,
              color: colors.secondaryText,
            }}
            onClick={openFileExplorer}
          >
            Upload CSV
          </button>
        </TERipple>
        <TERipple
          rippleColor="light"
          className="w-full flex flex-row justify-center mt-2"
        >
          <button
            className="w-full inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
            type="button"
            style={{
              backgroundColor: colors.secondary,
              color: colors.secondaryText,
            }}
            onClick={() => onClickRow(-1)}
          >
            Add a new Product
          </button>
        </TERipple>
      </div>

      <div className="w-full overflow-auto mb-6 rounded-t-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead
            className="uppercase"
            style={{
              backgroundColor: colors.primary,
              color: colors.primaryText,
            }}
          >
            <tr>
              {headerKeys.map((key, index) => (
                <th key={index} scope="col" className="px-3 py-3 text-left">
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {products.map((row, pIndex) => (
              <tr
                key={pIndex}
                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 border-b cursor-pointer"
                onClick={() => onClickRow(pIndex)}
              >
                {headerKeys.map((key, index) => (
                  <td key={index} className="px-3 py-3 text-left">
                    {unitsLeft[key]}
                    {key === "" ? (
                      <RiDeleteBin2Fill
                        size={20}
                        onClick={(e) => onClickDelete(e, pIndex)}
                        color="red"
                      />
                    ) : (
                      row[headerKeysFields[key]]
                    )}
                    {unitsRight[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <ProductModal
          showModal={showModal}
          setShowModal={setShowModal}
          product={selectedIndex !== -1 ? products[selectedIndex] : {}}
          fetchProducts={fetchProducts}
        />
      )}
      <LoadingModal
        showModal={showDeleteModal || showUploadingCSVModal}
        isLoading={isDeleting || isUploading}
        loadingTitle={isDeleting ? "Deleting Product" : "Uploading CSV"}
        doneTitle={isDeleting ? "Product Deleted" : "CSV Uploaded"}
        showTick={true}
      />
    </div>
  );
}
