import React, { useContext, useEffect, useState } from "react";
import { TEInput, TERipple } from "tw-elements-react";
import { useNavigate } from "react-router-dom";
import { FERoutes } from "../../routes/FERoutes";
import LoadingModal from "../../components/LoadingModal";
import { signInUser } from "../../services/signInUser";
import { showError } from "../../utils/showError";
import { useAuth } from "../../hooks/useAuth";
import { Light } from "../../themes/Light";
import logoPNG from "../../assets/images/logo.png";
import rightBackgroundPNG from "../../assets/images/auth/rightBackground.png";
import { Context } from "../../contexts.js/Context";
import { saveVisits } from "../../services/saveVisits";

export default function SignIn() {
  const colors = Light;
  const navigate = useNavigate();
  const context = useContext(Context);

  const auth = useAuth();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [loginUser, setLoginUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    saveVisits({ page: "SignIn" });
  }, []);

  const onClickSignIn = async () => {
    setShowModal(true);
    setIsLoading(true);
    const response = await signInUser({
      ...loginUser,
      username: loginUser.email,
    });
    if (response.error) {
      setShowModal(false);
      showError(response.error);
    } else {
      auth.login(response);
      setIsLoading(false);
      context.fetchCurrentUser(response.access_token);
      saveVisits({ page: "onClickSignIn" });
      setTimeout(async () => {
        setShowModal(false);
        response.user.userTypeId === 1
          ? navigate(`${FERoutes.NavigationBar}${FERoutes.Home}`)
          : navigate(`${FERoutes.SideBar}${FERoutes.Dashboard}`);
      }, 3000);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginUser({ ...loginUser, [name]: value });
  };

  const onClickForgotPassword = async () => {
    navigate(FERoutes.ForgotPassword);
  };

  return (
    <section className="h-full bg-neutral-200 dark:bg-neutral-700 flex flex-row justify-center">
      <div className="container h-full p-10">
        <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
          <div className="w-full">
            <div className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
              <div className="g-0 lg:flex lg:flex-wrap">
                {/* <!-- Left column container--> */}
                <div className="px-4 md:px-0 lg:w-6/12">
                  <div className="md:mx-6 md:p-12">
                    {/* <!--Logo--> */}
                    <div className="text-center">
                      <img className="mx-auto w-60 my-12" src={logoPNG} />
                      <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold capitalize">
                        Purchase from over thousands of verified brands and
                        distributors.
                      </h4>
                    </div>

                    <form>
                      <p className="mb-4">Please login to your account</p>
                      <TEInput
                        type="email"
                        label="Email"
                        className="mb-4"
                        name="email"
                        value={loginUser.email}
                        onChange={handleInputChange}
                      ></TEInput>

                      {/* <!--Password input--> */}
                      <TEInput
                        type="password"
                        label="Password"
                        className="mb-4"
                        name="password"
                        value={loginUser.password}
                        onChange={handleInputChange}
                      ></TEInput>

                      {/* <!--Submit button--> */}
                      <div className="pb-1 pt-1 text-center">
                        <TERipple rippleColor="light" className="w-full">
                          <button
                            onClick={onClickSignIn}
                            className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                            type="button"
                            // style={{
                            //   background:
                            //     "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                            // }}
                            style={{
                              backgroundColor: colors.secondary,
                              color: colors.secondaryText,
                            }}
                          >
                            Sign In
                          </button>
                        </TERipple>

                        {/* <!--Forgot password link--> */}
                        {/* <a href="#!">Forgot password?</a> */}
                      </div>
                      <p
                        className="mb-12 text-right cursor-pointer"
                        onClick={onClickForgotPassword}
                      >
                        Forgot your password?
                      </p>

                      {/* <!--Register button--> */}
                      <div className="flex items-center justify-between pb-6">
                        <p className="mb-0 mr-2">Don't have an account?</p>
                        {/* <TERipple rippleColor="light">
                          <button
                            onClick={() => navigate(FERoutes.SignUp)}
                            type="button"
                            className="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                          >
                            Sign Up
                          </button>
                        </TERipple> */}
                        <TERipple rippleColor="light">
                          <button
                            onClick={() => navigate(FERoutes.SignUp)}
                            className="inline-block rounded px-6 pb-2 pt-2.5  border-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                            type="button"
                            style={{
                              backgroundColor: colors.tertiary,
                              color: colors.tertiaryText,
                              borderColor: colors.tertiaryText,
                            }}
                          >
                            Sign Up
                          </button>
                        </TERipple>
                      </div>
                    </form>
                  </div>
                </div>

                {/* <!-- Right column container with background and description--> */}
                <div className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none relative">
                  <img
                    className="absolute left-0 top-0 h-full w-full rounded-r-lg object-fill"
                    src={rightBackgroundPNG}
                  />
                  {/* <div className="px-4 py-6 text-white md:mx-6 md:p-12 z-10">
                    <h4 className="mb-6 text-xl font-semibold capitalize">
                      We are more than just a company
                    </h4>
                    <p className="text-sm capitalize">
                      bazarr is the wholesale marketplace that works for you:
                      enabling an AI driven, automatic & personalized purchasing
                      solution between brands' products & resellers
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingModal
        showModal={showModal}
        isLoading={isLoading}
        loadingTitle={"Signing In"}
        doneTitle={"Signed In"}
        showTick={true}
      />
    </section>
  );
}
