export const BERoutes = {
  registerUser: "/auth/register",
  tokenUser: "/auth/token",
  currentUser: "/auth/current",
  runRecommendation: "/recommendations/run",
  updateUser: "/auth/update",
  generations: "/recommendations/generations",
  generationsToday: "/recommendations/generationsToday",
  analytics: "/analytics",
  visits: "/analytics/visits",
  orderStatus: "/payment/orderStatus",
  resellers: "/analytics/resellers",
  order: "/order",
  createProduct: "/product/create",
  products: "/product",
  deleteProduct: "/product/delete",
  bulkProduct: "/product/bulk",

  forgotPassword: "/auth/forgotPassword",
  resetPassword: "/auth/resetPassword",

  createCheckoutSession: "/payment/createCheckoutSession",
  addToCart: "/payment/addToCart",
  inCart: "/payment/inCart",
};
