import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Light } from "../../themes/Light";

import logoPNG from "../../assets/images/logo.png";
import { Outlet, useNavigate } from "react-router-dom";
import { FERoutes } from "../../routes/FERoutes";

export default function SideBar() {
  // State to manage the navbar's visibility
  const colors = Light;
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Array containing navigation items
  const navItems = [
    {
      id: 1,
      text: "Dashboard",
      route: `${FERoutes.SideBar}${FERoutes.Dashboard}`,
    },
    {
      id: 2,
      text: "Resellers",
      route: `${FERoutes.SideBar}${FERoutes.Reseller}`,
    },
    {
      id: 3,
      text: "Catalog",
      route: `${FERoutes.SideBar}${FERoutes.Catalog}`,
    },
    {
      id: 4,
      text: "Logout",
      route: FERoutes.SignIn,
    },
  ];
  return (
    <>
      <nav
        class="fixed top-0 z-50 w-full dark:bg-gray-800 dark:border-gray-700"
        style={{ backgroundColor: colors.primary }}
      >
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start rtl:justify-end">
              <a class="flex ms-2 md:me-24">
                <img src={logoPNG} class="h-8 me-3" alt="FlowBite Logo" />
              </a>
            </div>
            <div class="flex items-center">
              <div class="fixed md:hidden items-center ms-3 right-2">
                <div
                  class="inline-flex items-center p-2 text-sm text-white rounded-lg sm:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  onClick={handleNav}
                >
                  {nav ? (
                    <AiOutlineClose size={20} />
                  ) : (
                    <AiOutlineMenu size={20} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        class="fixed hidden md:flex top-0 left-0 z-40 w-48 h-screen pt-20"
        aria-label="Sidebar"
        style={{ backgroundColor: colors.primary }}
      >
        <ul class="space-y-2 font-medium w-full">
          {navItems.map((item) => (
            <li
              key={item.id}
              className="p-4 rounded-xl text-white cursor-pointer border-gray-600"
              onClick={() => {
                handleNav();
                navigate(item.route);
              }}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </aside>

      <ul
        className={
          nav
            ? `fixed md:hidden left-0 top-0 w-[50%] h-full border-r border-r-gray-900 ease-in-out duration-500 z-50`
            : "fixed md:hidden ease-in-out w-[50%] duration-500 fixed top-0 bottom-0 left-[-100%] z-50"
        }
        style={{ backgroundColor: colors.primary }}
      >
        {/* Mobile Logo */}
        <img className="h-10 ml-4 mt-3" src={logoPNG} />

        {/* Mobile Navigation Items */}
        {navItems.map((item) => (
          <li
            key={item.id}
            className="p-4 border-b rounded-xl hover:bg-[#00df9a] duration-300 text-white hover:text-black cursor-pointer border-gray-600"
            onClick={() => {
              handleNav();
              navigate(item.route);
            }}
          >
            {item.text}
          </li>
        ))}
      </ul>

      <div class="md:ml-64 mt-16">
        <Outlet />
      </div>
    </>
  );
}
