import React, { useContext } from "react";
import { Light } from "../themes/Light";
import { Context } from "../contexts.js/Context";
import { TERipple } from "tw-elements-react";
import { useAuth } from "../hooks/useAuth";

export default function AlertModal(props) {
  const colors = Light;
  const context = useContext(Context);
  const auth = useAuth();

  return (
    <>
      {props.showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
            onClick={(e) => {
              e.stopPropagation();
              props.setShowModal && props.setShowModal(false);
            }}
          >
            <div className="relative my-6 mx-auto max-w-3xl w-full">
              {/*content*/}
              <div className="justify-center items-center border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div
                  className="flex flex-row justify-center p-5 rounded-t-lg w-full"
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.primaryText,
                  }}
                >
                  <h3 className="text-2xl font-semibold">{props.heading}</h3>
                </div>
                {/*body*/}
                <div
                  className={`flex flex-col gap-1 p-3 rounded-b-lg w-full`}
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.primaryText,
                  }}
                >
                  <p className="">{props.desc}</p>
                  <div className="flex flex-row gap-2">
                    <TERipple
                      rippleColor="light"
                      className="w-full flex flex-row justify-end"
                    >
                      <button
                        className="w-full inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                        type="button"
                        style={{
                          backgroundColor: "green",
                          color: colors.secondaryText,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          console.log("onClickContinue");
                          props.onClickContinue && props.onClickContinue();
                        }}
                      >
                        Continue
                      </button>
                    </TERipple>
                    <TERipple
                      rippleColor="light"
                      className="w-full flex flex-row justify-end"
                    >
                      <button
                        className="w-full inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                        type="button"
                        style={{
                          backgroundColor: "red",
                          color: colors.secondaryText,
                        }}
                        onClick={() =>
                          props.setShowModal && props.setShowModal(false)
                        }
                      >
                        Cancel
                      </button>
                    </TERipple>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
