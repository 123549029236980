import React, { useEffect, useState } from "react";
import { Light } from "../themes/Light";
import { TERipple } from "tw-elements-react";
import { createProduct } from "../services/createProduct";
import { useAuth } from "../hooks/useAuth";
import { showError } from "../utils/showError";
import LoadingModal from "./LoadingModal";
import { isEmpty } from "../utils/isEmpty";

export default function ProductModal(props) {
  const colors = Light;
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const headerKeysFields = {
    ASIN: "ASIN",
    "Price Per Unit": "pricePerUnit",
    "Your Estimated Monthly Sales": "yourEstimatedMonthlySales",
    "Recommended Sell Price Per Unit": "recommendedSellPricePerUnit",
    "Gross Profit Per Unit": "grossProfitPerUnit",
    ROI: "ROI",
    MOQ: "MOQ",
    "Recommended Purchase Quantity": "recommendedPurchaseQuantity",
    Sellers_ID: "sellerId",
    "Units Prediction": "unitsPrediction",
    Supplier: "supplier",
  };

  const [fields, setFields] = useState({
    ASIN: { value: null, title: "ASIN", type: "text", disabled: false },
    pricePerUnit: {
      value: null,
      title: "Price Per Unit",
      type: "text",
      disabled: false,
    },
    yourEstimatedMonthlySales: {
      value: null,
      title: "our Estimated Monthly Sales",
      type: "text",
      disabled: false,
    },
    recommendedSellPricePerUnit: {
      value: null,
      title: "Recommended Sell Price Per Unit",
      type: "text",
      disabled: false,
    },
    grossProfitPerUnit: {
      value: null,
      title: "Gross Profit Per Unit",
      type: "text",
      disabled: false,
    },
    ROI: {
      value: null,
      title: "ROI",
      type: "text",
      disabled: false,
    },
    MOQ: {
      value: null,
      title: "MOQ",
      type: "text",
      disabled: false,
    },
    recommendedPurchaseQuantity: {
      value: null,
      title: "Recommended Purchase Quantity",
      type: "text",
      disabled: false,
    },
    sellerId: {
      value: null,
      title: "Sellers_ID",
      type: "text",
      disabled: false,
    },
    unitsPrediction: {
      value: null,
      title: "Units Prediction",
      type: "text",
      disabled: false,
    },
    supplier: {
      value: null,
      title: "Supplier",
      type: "text",
      disabled: false,
    },
    stripeAccountId: {
      value: null,
      title: "STRIPE ACCOUNT ID",
      type: "text",
      disabled: false,
    },
    amazonLink: {
      value: null,
      title: "AMAZON LINK",
      type: "text",
      disabled: false,
    },
  });

  useEffect(() => {
    setFields((prevFields) => {
      const tempFields = { ...prevFields };
      for (const key in tempFields) tempFields[key].value = props.product[key];
      return tempFields;
    });
  }, [props.product]);

  const onChangeField = (key, value) => {
    setFields((prevFields) => {
      const tempFields = { ...prevFields };
      tempFields[key].value = value;
      return tempFields;
    });
  };

  const onClickAdd = async (e) => {
    e.stopPropagation();
    setShowModal(true);
    setIsLoading(true);
    const tempFields = { ...fields };
    Object.entries(tempFields).forEach(
      ([key, value]) => (tempFields[key] = `${value.value}`)
    );
    const response = await createProduct(auth.authed.access_token, tempFields);
    if (response.error) {
      setShowModal(false);
      showError(response.error);
    } else {
      props.fetchProducts();
      setIsLoading(false);
      setTimeout(async () => {
        setShowModal(false);
        props.setShowModal(false);
      }, 3000);
    }
  };

  return (
    <>
      {props.showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
            onClick={(e) => {
              e.stopPropagation();
              props.setShowModal && props.setShowModal(false);
            }}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl h-5/6">
              {/*content*/}
              <div
                className="justify-center items-center border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-20 h-full"
                onClick={(e) => e.stopPropagation()}
              >
                {/*header*/}
                <div className="flex flex-row justify-center p-5 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    {isEmpty(props.product) ? "ADD PRODUCT" : "UPDATE PRODUCT"}
                  </h3>
                </div>
                <div className="overflow-y-auto">
                  {Object.entries(fields).map(([key, field], index) => (
                    <label key={index} className="block mb-6">
                      <span className="text-gray-700">{field.title}</span>
                      <input
                        name={key}
                        type={field.type}
                        className="
                        block
                        w-full
                        mt-1
                        border-2
                        border-gray-200
                        rounded-md
                        shadow-sm
                        focus:border-indigo-300
                        focus:ring
                        focus:ring-indigo-200
                        focus:ring-opacity-50
                        px-4
                        py-1
                    "
                        value={field.value}
                        disabled={field.disabled}
                        onChange={(e) => onChangeField(key, e.target.value)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </label>
                  ))}
                </div>

                <TERipple
                  rippleColor="light"
                  className="w-full flex flex-row justify-center"
                >
                  <button
                    className="w-full inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                    type="button"
                    style={{
                      backgroundColor: colors.secondary,
                      color: colors.secondaryText,
                    }}
                    onClick={(e) => onClickAdd(e)}
                  >
                    {isEmpty(props.product) ? "ADD" : "UPDATE"}
                  </button>
                </TERipple>
              </div>
            </div>
            <LoadingModal
              showModal={showModal}
              isLoading={isLoading}
              loadingTitle={
                isEmpty(props.product) ? "Saving Product" : "Updating Product"
              }
              doneTitle={
                isEmpty(props.product) ? "Product Saved " : "Product Updated"
              }
              showTick={true}
            />
          </div>
        </>
      ) : null}
    </>
  );
}
